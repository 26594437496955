<template>
  <div class="w-100 my-5">
    <div v-if="storyList">
      <div class="row mx-auto box-white">
        <div class="col-sm my-1 vertical-center">
          <span class="font-bold font-15 alpha-07">Story:</span>
          <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
        </div>
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
      <div class="row my-5" v-if="(items.length > 0)">
        <div :class="`col-sm${colSmSize}`" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_200, items.length))" :key="(item, index)">
          <v-card class="my-2 p-0 box-white d-flex flex-column">
            <v-img style="cursor: zoom-in" height="400px" :src="item.photoUrl" @click="openImageViewer(index)" v-if="item.photoUrl" />
            <video height="400px" controls preload="metadata" :src="item.videoUrl" v-if="item.videoUrl" />
            <div class="mx-2 mt-1 vertical-center">
              <img class="circle-image-30" :src="item.userPhotoUrl" />
              <span class="ml-2 ellipsis font-12" :title="item.userName">{{ item.userName }}</span>
            </div>
            <div class="mx-3 my-2 d-flex justify-content-between vertical-center ">
              <i class="fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
              <v-btn class="ml-auto" icon small @click="deleteStoryInfoTask(item.storyId)">
                <i class="fa fa-trash color-red"></i>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_200 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">No story found!</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>Please wait ...</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <!-- VueEasyLightbox Component -->
    <VueEasyLightbox
      :visible="visibleImageViewer"
      :imgs="images"
      :index="imageIndex"
      @hide="visibleImageViewer = false"
      />
    <!-- VueEasyLightbox Component end -->
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'StoryList',
  components: {
    VueEasyLightbox
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    storyList() {
      return this.$store.state.storyList;
    }
  },
  watch: {
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    storyList() {
      this.refreshData();
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      visibleImageViewer: false,
      imageIndex: 0,
      images: [],
      pageIndex: 1,
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    refreshData() {
      if (this.storyList) {
        const items = [];
        const keyword = this.keyword.toLowerCase();
        this.storyList.forEach(storyInfo => {
          const userInfo = this.getUserInfo(storyInfo.userId);
          if (userInfo && userInfo.userName.toLowerCase().includes(keyword)) {
            const item = JSON.parse(JSON.stringify(storyInfo));
            item['userName'] = userInfo.userName;
            item['userPhotoUrl'] = userInfo.thumbnailUrl || userInfo.photoUrl || this.assets.profile;
            items.push(item);
          }
        });
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.images = [];
        items.forEach(info => {
          if (info.photoUrl) {
            this.images.push({
              title: info.description,
              src: info.photoUrl
            });
          }
        });
        this.items = items;
      }
    },
    getUserInfo(userId) {
      const cacheInfo = this.cacheUserList.find(element => element.userId === userId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const userInfo = this.organizerList.find(element => element.userId === userId);
        if (userInfo) {
          this.cacheUserList.push(userInfo);
        }
        return userInfo;
      }
    },
    openImageViewer(index) {
      this.imageIndex = index;
      this.visibleImageViewer = true;
    },
    deleteStoryInfoTask(storyId) {
      if (!confirm(this.$t('confirm_delete_story'))) {
        return;
      }
      const params = {
        functionName: 'deleteStory',
        storyId: storyId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>